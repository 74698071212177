var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('sidebar-basic')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('sidebar-placement')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('sidebar-variant')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('sidebar-header')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('sidebar-footer')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('sidebar-without-backdrop')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }