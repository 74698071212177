var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "size": "72px",
      "src": require('@/assets/images/portrait/small/avatar-s-8.jpg')
    }
  }), _c('b-card-text', {
    staticClass: "mt-2 h4 color-inherit text-reset"
  }, [_vm._v(" Black Widow ")])], 1), _c('b-list-group', {
    staticClass: "mt-3 rounded-0"
  }, _vm._l(_vm.sidebarItems, function (item) {
    return _c('b-list-group-item', {
      key: item.title,
      staticClass: "bg-transparent"
    }, [_c('feather-icon', {
      staticClass: "align-text-bottom",
      attrs: {
        "icon": item.icon
      }
    }), _c('span', {
      staticClass: "ml-1",
      domProps: {
        "textContent": _vm._s(item.title)
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }